import React from "react"
import Layout from "../components/layout"

import * as styles from "./karriere.module.scss";
import { StaticImage } from "gatsby-plugin-image"

const QmPage = () => {

  return <Layout apply={"Qualitäts Management"}>

    <div className={styles.carrerPage}>

      <div
        className={styles.introImage}>
      <StaticImage
        src="../images/schnitzel.jpg"
        width={500}
        quality={85}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Schnitzel" />

        <p>Handel & Verkauf von Lebensmittel,<br /> Herstellung von Schnitzel & Cordon Bleu</p>

      </div>


        <div>
          <h1>Qualitäts-Management (m/w/d)</h1>
          <h3>38,5 Stunden</h3>
        </div>


      <h2>Ihre Aufgaben</h2>
      <ul>
        <li><b>Qualitätssicherung</b> gemäß Zertifikationsanforderungen (IFS, HACCP)</li>

        <li>
          Weiterentwicklung der <b>QM-Systeme und Prozesse</b> sowie Monitoring von produkt- und prozessbegleitenden
          Prüfkriterien
        </li>

        <li>
          Umsetzung des IFS-Standards und Ansprechpartner für alle QM-Fragestellungen
        </li>

        <li>
          Laufende <b>Kontrolle</b> von Produkten und Prozessen
        </li>

        <li>
          Planung und Durchführung interner Audits sowie Hygienekontrollen
        </li>

        <li>
          Prüfung und Erstellung von Deklarationen und <b>Produkt-Spezifikationen</b>
        </li>

        <li>
          Reklamationsbearbeitung und entsprechende Auswertungen
        </li>
      </ul>

      <h2>Das zeichnet Sie aus</h2>
      <ul>
        <li>Abgeschlossene Ausbildung auf <b>Maturaniveau</b></li>
        <li>
          <b>Mehrjährige Berufserfahrung</b> in einer vergleichbaren Position</li>

        <li>Erfahrungen im Lebensmittelbereich wünschenswert</li>

        <li>Facheinschlägige Zusatzqualifikationen von Vorteil
          <br />
          (Hygienemanager, Qualitätsmanager, Qualitätsbeauftragter,
          Lebensmittelkennzeichnung)
        </li>

        <li>Sehr gute MS-Office-Kenntnisse</li>
        <li> Ausgezeichnete Deutschkenntnisse und gute Englischkenntnisse</li>
        <li> Teamfähigkeit</li>
        <li> Eigenständige, strukturierte und genaue Arbeitsweise</li>

      </ul>

      <h2>Wir bieten Ihnen</h2>

      <ul>
        <li>Ein <b>familiäres Arbeitsklima</b> in einem innovativen Vorzeigebetrieb</li>
        <li>Die <b>Sicherheit</b> einer langfristigen Anstellung in einem wachsenden Unternehmen</li>
        <li>Ein vielseitiges Aufgabengebiet mit beruflichen Entwicklungsmöglichkeiten</li>
        <li>Gemeinsam mit uns unseren Weg gehen: „Die Welt jeden Tag ein bisschen besser machen!“</li>
      </ul>

      <p className={styles.gehalt + " mt-5"}>
        Das Mindestbruttogehalt für diese Position beträgt EUR 2.700,00 pro Monat (für 38,5 Stunden/Woche).
      </p>

      <p className={styles.gehaltAdd + " mt-2"}>
        Eine Überzahlung ist möglich und abhängig von Ihrer bisherigen Berufserfahrung und Qualifikation.
      </p>

    </div>

  </Layout>

}

export default QmPage
